import { Box, Form, Heading, ResponsiveContext, Text } from "grommet";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { createBooking, fetchAvailableSlots } from "@Api/booking";
import {
  ContactMethodType,
  ExtraFieldResult,
  FieldName,
  NotificationType,
  PostBooking,
} from "@Api/bookingTypes";
import { ErrorType } from "@Api/errorTypes";

import {
  setAvailableSlots,
  setBooking,
  setExtraFieldResults,
  setPage,
  setPatient,
} from "@Hooks/useBooking/actions";
import { BookingPage, PatientFormFieldData } from "@Hooks/useBooking/types";
import useBookingAction from "@Hooks/useBooking/useBookingAction";
import useBookingState from "@Hooks/useBooking/useBookingState";
import { pushErrorAction } from "@Hooks/useError/actions";
import useErrorAction from "@Hooks/useError/useErrorAction";

import Button from "../Common/Button";
import CheckBox from "../Common/CheckBox";
import PageContainer from "../Common/PageContainer";
import SlotComponent from "../Common/SlotComponent";
import PatientFormField from "./PatientFormField";
import messages from "./messages";

interface Props {}

const PatientDetailsWrapper = styled(Box)`
  background: ${({ theme }) => theme.global.colors.infoBoxBackground};
  border: 1px solid ${({ theme }) => theme.global.colors.infoBoxBorder};
  border-radius: 4px;
`;

const ConsentWrapper = styled(Box)`
  background: ${({ theme }) => theme.global.colors.infoBoxBackground};
  border: 1px solid ${({ theme }) => theme.global.colors.brand};
  border-radius: 4px;
  padding: 12px 22px;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  & > label {
    margin: 10px 0;
  }
`;

interface FormValue {
  key: string;
  value?: string;
}

const PatientForm: React.FC<Props> = () => {
  const errorDispatch = useErrorAction();
  const bookingDispatch = useBookingAction();
  const {
    apiClient,
    extraFieldResults,
    filter,
    patientDetails,
    selectedSlot,
    clientId,
    companySettings,
  } = useBookingState();
  const { formatMessage } = useIntl();
  const [consented, setConsented] = useState(false);
  const [notConsented, setNotConsented] = useState(false);
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [allowSms, setAllowSms] = useState(false);
  const [phoneMissing, setPhoneMissing] = useState(false);
  const [fetchingSlots, setFetchingSlots] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const size = React.useContext(ResponsiveContext);
  const small = "xsmall" === size;
  const width = (small ? 360 : 600) + "px";
  const formFieldWidth = (small ? 350 : 290) + "px";

  const [fields, setFields] = useState<PatientFormFieldData[]>([]);
  const [bookingOngoing, setBookingOngoing] = useState(false);

  const topOfFormRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      const merged = companySettings!.extraFields
        .map<PatientFormFieldData>((field) => ({
          label: field.name,
          name: field.id,
          value: undefined,
          mandatory: field.mandatory,
          invalid: false,
          errorMessage: "",
        }))
        .map((field) => {
          const extraField = extraFieldResults.find(
            (value) =>
              value.key === field.name ||
              (field.name === FieldName.email_check &&
                value.key === FieldName.email)
          );
          if (extraField) {
            field.value = extraField.value;
          } else if (field.name === FieldName.firstname) {
            field.value = patientDetails?.firstName;
          } else if (field.name === FieldName.lastname) {
            field.value = patientDetails?.lastName;
          } else if (field.name === FieldName.email) {
            field.value = patientDetails?.contactMethods?.find(
              (cm) => cm.type === ContactMethodType.EMAIL
            )?.value;
          } else if (field.name === FieldName.phone) {
            field.value = patientDetails?.contactMethods?.find(
              (cm) => cm.type === ContactMethodType.PHONE_HOME
            )?.value;
          } else if (field.name === FieldName.address) {
            field.value = patientDetails?.streetAddress;
          } else if (field.name === FieldName.city) {
            field.value = patientDetails?.city;
          } else if (field.name === FieldName.postnr) {
            field.value = patientDetails?.zipCode;
          } else if (field.name === FieldName.cellphone) {
            field.value = patientDetails?.contactMethods?.find(
              (cm) => cm.type === ContactMethodType.PHONE_MOBILE
            )?.value;
          }
          return field;
        });
      setSmsEnabled(companySettings!.isSmsNotificationsEnabled);
      setFields(merged);
    }
    return () => {
      isCancelled = true;
    };
  }, [patientDetails, extraFieldResults, companySettings]);

  if (!selectedSlot || !patientDetails || !apiClient) {
    return null;
  }

  const bookingSmsReminderHoursBefore =
    companySettings?.bookingSmsReminderHoursBefore ?? 1;
  const allowBookingSmsReminder = moment().isSameOrBefore(
    moment(selectedSlot.startTime).subtract(bookingSmsReminderHoursBefore, "h")
  );

  const handleBack = async () => {
    setBookingOngoing(false);
    bookingDispatch(setPage(BookingPage.SELECT_SLOT));
  };

  const getFormValues = (
    data: PatientFormFieldData[],
    form: any[]
  ): PatientFormFieldData[] => {
    let values: FormValue[];
    values = [];
    for (let i = 0; i < form.length; i++) {
      values.push({ key: form[i].name, value: form[i].value?.trim() });
    }
    return data.map((field) => {
      const formValue: string | undefined = values.find(
        (f) => f.key === field.name
      )?.value;
      return { ...field, value: formValue };
    });
  };

  const getBookingData = (formFields: PatientFormFieldData[]): PostBooking => {
    const patientData = {
      ...patientDetails,
      firstName: formFields.find((field) => field.name === FieldName.firstname)!
        .value,
      lastName: formFields.find((field) => field.name === FieldName.lastname)!
        .value,
      phoneNumber: formFields.find((field) => field.name === FieldName.phone)!
        .value,
      email: formFields.find((field) => field.name === FieldName.email)!.value,
    };
    const extraFields: ExtraFieldResult[] = formFields
      .filter(
        (field) =>
          field.name !== FieldName.firstname &&
          field.name !== FieldName.lastname &&
          field.name !== FieldName.phone &&
          field.name !== FieldName.email &&
          field.name !== FieldName.email_check
      )
      .map((field) => ({ key: field.name, value: field.value }));
    extraFields.push({ key: "client_id", value: clientId });
    let notifications = [];
    if (consented && patientData.email) {
      notifications.push({
        type: NotificationType.EMAIL,
        value: patientData.email,
        sendConfirmation: consented,
        sendReminder: false,
      });
    }
    const cellphone = extraFields.find(
      (field) => field.key === FieldName.cellphone
    )?.value;
    if (allowSms && cellphone) {
      notifications.push({
        type: NotificationType.SMS,
        value: cellphone,
        sendConfirmation: false,
        sendReminder: allowSms,
      });
    }

    return {
      id: selectedSlot.bookingId,
      company: { customerNumber: clientId },
      careUnit: { id: selectedSlot.resource.careUnit.id },
      endTime: selectedSlot.endTime,
      patient: patientData,
      resource: { id: selectedSlot.resource.id },
      notifications: notifications,
      startTime: selectedSlot.startTime,
      extraFields: extraFields,
      videoConsultation: selectedSlot.serviceType?.videoConsultation,
      mainService: filter.service
        ? { ...filter.service }
        : { id: "", name: "", subContractId: "", duration: 0 },
      internalComment: selectedSlot.callText,
    };
  };

  const isValidEmail = (email: string): boolean => {
    const re = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    return re.test(email);
  };

  const isValidPhone = (phone: string): boolean => {
    const reFormat = /^\+?[\d -]+$/;
    const reCountDash = /-/g;
    const reCountDigits = /\d/g;
    return (
      phone.length > 0 &&
      (phone.match(reCountDash) || []).length < 2 &&
      (phone.match(reCountDigits) || []).length >= 1 &&
      reFormat.test(phone)
    );
  };

  const getErrorMessage = (name: string): string => {
    switch (name) {
      case FieldName.firstname:
        return formatMessage(messages.invalidFirstName);
      case FieldName.lastname:
        return formatMessage(messages.invalidLastName);
      case FieldName.email:
        return formatMessage(messages.invalidEmail);
      case FieldName.email_check:
        return formatMessage(messages.invalidEmailCheck);
      case FieldName.phone:
        return formatMessage(messages.invalidPhone);
      case FieldName.cellphone:
        return formatMessage(messages.invalidCellphone);
    }
    return formatMessage(messages.invalidCustomField);
  };

  const handleBook = async (event: any) => {
    setBookingOngoing(true);

    const formValues = getFormValues(fields, event.target.form);
    let isValid = true;
    const validated = formValues.map((item, index) => {
      let invalidItem: boolean;
      if (item.name === FieldName.email) {
        invalidItem = !isValidEmail(item.value || "");
      } else if (item.name === FieldName.email_check) {
        invalidItem =
          item.value !==
          formValues.find((field) => field.name === FieldName.email)!.value;
      } else if (
        item.name === FieldName.phone ||
        item.name === FieldName.cellphone
      ) {
        invalidItem = !isValidPhone(item.value || "");
        if (!item.mandatory && item.value && !isValidPhone(item.value || "")) {
          //Fail on phone is wrong format even if not mandatory
          item.errorMessage = getErrorMessage(item.name);
          invalidItem = true;
          isValid = false;
        }
      } else {
        invalidItem = !item.value || item.value === "";
      }

      if (invalidItem) {
        item.invalid = true;
        if (item.mandatory) {
          item.errorMessage = getErrorMessage(item.name);
          isValid = false;
        }
      } else {
        item.invalid = false;
      }

      // Check maximum length of string
      if (item.value) {
        const currentLength = item.value.length;
        let maxLength = 50;
        if (item.name === FieldName.email) {
          maxLength = 100;
        } else if (item.name === FieldName.additionalInfo) {
          maxLength = 250;
        }

        if (currentLength > maxLength) {
          isValid = false;
          item.invalid = true;
          item.errorMessage = formatMessage(messages.fieldValueTooLong);
        }
      }

      if (item.name === FieldName.cellphone) {
        if (smsEnabled && allowSms && item.invalid) {
          item.errorMessage = getErrorMessage(item.name);
          isValid = false;
          setPhoneMissing(true);
        } else {
          setPhoneMissing(false);
        }
      }

      return item;
    });

    if (isValid && consented) {
      const data = getBookingData(validated);

      createBooking(data)
        .then((booking) => {
          bookingDispatch(setBooking(booking));
          // If the booking resulted in the patient being created we need to
          // update patient details in the state.
          if (!patientDetails.id && booking?.patient.id) {
            bookingDispatch(setPatient(booking.patient));
          }
          navigate(
            "/book/" + (booking.id || "confirmation") + (location.search || "")
          );
        })
        .catch((reason) => {
          bookingDispatch(
            setExtraFieldResults(
              validated.map((result) => ({
                key: result.name,
                value: result.value,
              }))
            )
          );

          let message;

          let error = reason?.response?.data["message"];
          switch (error) {
            case ErrorType.VIDEO_CONFIGURATION_ERROR:
              message = messages.videoConfigurationError;
              break;
            case ErrorType.BOOKING_OCCUPIED:
              message = messages.bookingOccupied;
              break;
            case ErrorType.VIDEO_WOULD_RESULT_IN_COMPANY_BOOKING_WITH_NO_PATIENT:
              message = messages.videoWouldResultInCompanyBookingWithNoPatient;
              break;
            default:
              message = messages.bookingFailedMessage;
          }

          errorDispatch(
            pushErrorAction({
              title: formatMessage(messages.bookingFailedTitle),
              message: formatMessage(message),
            })
          );
        });
    } else {
      setBookingOngoing(false);
      setFields(validated);
      setNotConsented(!consented);
      topOfFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fieldsSortOrder = ["firstname", "lastname", "email", "email-check", "cellphone", "phone", "department", "boss", "billing_address", "address", "postnr", "city", "additional-info"];
  fields.sort((a, b) => fieldsSortOrder.indexOf(a.name) - fieldsSortOrder.indexOf(b.name));

  return (
    <PageContainer label={formatMessage(messages.header)} width={width}>
      <Box direction="row" wrap={true} fill="horizontal" flex={"grow"}>
        <Box
          flex
          width={{ min: "auto" }}
          justify={"end"}
          height={{ min: "23px" }}>
          <Heading margin="none" level="2">
            {formatMessage(messages.patientDetails)}
          </Heading>
        </Box>
        <Box width={{ min: "auto" }} justify="end" height={{ min: "23px" }}>
          <Text>
            {formatMessage(messages.personalIdentificationNumber)}{" "}
            {patientDetails.civicRegNumber}
          </Text>
        </Box>
      </Box>
      <SlotComponent
        small={small}
        video={selectedSlot.serviceType?.videoConsultation}
        serviceName={selectedSlot.serviceName}
        careUnit={selectedSlot.resource.careUnit}
        resource={selectedSlot.resource}
        startTime={selectedSlot.startTime}
        callText={selectedSlot.callText}
      />
      <Box
        direction="row"
        wrap={true}
        fill="horizontal"
        margin={{ top: "30px" }}
        flex={"grow"}>
        <Box flex width={{ min: "300px" }} height={{ min: "22px" }}>
          <Text>{formatMessage(messages.inputPatientDetails)}</Text>
        </Box>
        <Box width={{ min: "auto" }} justify="end" height={{ min: "22px" }}>
          <Text>{formatMessage(messages.mandatoryFields)}</Text>
        </Box>
      </Box>

      <Box margin={{ top: "10px" }}>
        <Form>
          <PatientDetailsWrapper ref={topOfFormRef}>
            <Box direction="row" wrap={true} pad={{ vertical: "25px" }}>
              {fields.map((field, index) => (
                <PatientFormField
                  width={formFieldWidth}
                  key={"patient_form_field_" + index}
                  label={field.label + (field.mandatory ? " *" : "")}
                  name={field.name}
                  defaultValue={field.value || ""}
                  invalid={field.invalid}
                  errorMessage={field.errorMessage}
                />
              ))}
            </Box>
          </PatientDetailsWrapper>
          <ConsentWrapper margin={{ top: "25px" }}>
            <CheckBox
              error={notConsented && !consented}
              checked={consented}
              label={formatMessage(messages.checkboxConsent)}
              onChange={(event) => setConsented(event.target.checked)}
            />
            {smsEnabled && allowBookingSmsReminder && (
              <CheckBox
                error={allowSms && phoneMissing}
                checked={allowSms}
                label={formatMessage(messages.checkboxSms)}
                onChange={(event) => setAllowSms(event.target.checked)}
              />
            )}
          </ConsentWrapper>
          <Box margin={{ top: "15px" }}>
            <Text>
              {formatMessage(messages.informationCancelBefore, {
                hours:
                  companySettings!.bookingCancelHoursBefore != null
                    ? companySettings!.bookingCancelHoursBefore
                    : apiClient.bookingCancelHoursBefore,
              })}
            </Text>
          </Box>
          <Box margin={{ top: "15px" }}>
            <Text>{formatMessage(messages.informationCancelEmail)}</Text>
          </Box>
          <Box direction="row" pad={{ vertical: "25px" }}>
            <Box align="start" width="50%">
              <Button
                width={"100px"}
                label={formatMessage(messages.buttonBack)}
                secondary
                type="button"
                showSpinner={fetchingSlots}
                onClick={handleBack}
              />
            </Box>
            <Box align="end" width="50%">
              <Button
                disabled={bookingOngoing}
                width={"100px"}
                label={formatMessage(messages.buttonBook)}
                primary
                type="submit"
                onClick={handleBook}
              />
            </Box>
          </Box>
        </Form>
      </Box>
    </PageContainer>
  );
};

export default PatientForm;
