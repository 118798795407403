import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "@Components/Common/Spinner";
import theme from "@Style/theme";
import { Box, Heading } from "grommet";
import useBookingAction from "@Hooks/useBooking/useBookingAction";
import {
  setBookingId,
  setClientId,
  setCompanySettings,
  setPatient,
  setPortalSettings
} from "@Hooks/useBooking/actions";
import { fetchPortalSettings } from "@Api/filter";
import { finalizeLoginSession } from "@Api/auth";
import { LoginSessionParameters } from "@Api/authTypes";
import { useIntl } from "react-intl";
import messages from "@Components/Login/messages";
import Button from "@Components/Common/Button";


const FederatedLoginCallback = () => {
  const [queryParams] = useSearchParams();
  const bookingDispatch = useBookingAction();
  const { formatMessage } = useIntl();

  const [error, setError] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [portalSettingsLoaded, setPortalSettingsLoaded] = useState(false);

  const navigate = useNavigate();

  const loginParameters = {
    grandIdSession: queryParams.get("grandidsession") ?? "",
    clientId: queryParams.get("clientId") ?? "",
    bookingId: queryParams.get("bookingId") ?? ""
  };

  useEffect(() => {
    if (!queryParams) {
      return;
    }

    const verifySession = async (params: LoginSessionParameters) => {
      try {
        let loginResponse = await finalizeLoginSession(params);
        if (!ignore) {
          bookingDispatch(setPatient(loginResponse.patient));
          if (loginResponse.company?.settings) {
            bookingDispatch(setCompanySettings(loginResponse.company.settings));
          }
          if (loginResponse.company?.customerNumber) {
            bookingDispatch(setClientId(loginResponse.company.customerNumber));
          }
          if (params.bookingId) {
            bookingDispatch(setBookingId(params.bookingId));
          }
        }
      } catch (error) {
        setError(true);
      }
    };

    const loadPortalSettings = async () => {
      try {
        const portalSettings = await fetchPortalSettings();
        if (!ignore) {
          bookingDispatch(setPortalSettings(portalSettings));
        }
      } catch (error) {
        setError(true);
      }
    };

    let ignore = false;

    verifySession(loginParameters).then(() => setLoggedIn(true));
    loadPortalSettings().then(() => setPortalSettingsLoaded(true));

    return () => {
      ignore = true;
    };
  }, []);

  if ((!loggedIn || !portalSettingsLoaded) && !error) {
    return (
      <Box pad={"medium"} gap={"medium"}>
        <Spinner color={theme.global.colors.text} size={35} />
        <Heading level={2} alignSelf={"center"} textAlign={"center"}>
          {formatMessage(messages.loggingIn)}
        </Heading>
      </Box>
    );
  } else if (error) {
    return (
      <Box pad={"medium"} gap={"medium"}>
        <Heading level={2} alignSelf={"center"} textAlign={"center"}>
          {formatMessage(messages.loginFailed)}
        </Heading>
        <Button
          width={"100px"}
          label={formatMessage(messages.button)}
          secondary
          type="button"
          onClick={() => navigate("/?client_no=" + loginParameters.clientId)}
        />
      </Box>);
  } else {
    return loginParameters.bookingId ?
        <Navigate to={`/book/${loginParameters.bookingId}?client_no=${loginParameters.clientId}`} /> :
        <Navigate to={`/?client_no=${loginParameters.clientId}`} />;
  }
};

export default FederatedLoginCallback;