import { Box, Heading, Image, ResponsiveContext } from "grommet";
import React from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import useBookingState from "@Hooks/useBooking/useBookingState";

import SlotComponent from "../Common/SlotComponent";
import messages from "./messages";

interface Props {}

const Wrapper = styled(Box)`
  & {
    z-index: 10;
    box-shadow: 0px 2px 0px ${({ theme }) => theme.global.colors.shadow},
      0px 1px 4px rgba(0, 0, 0, 0.25), 0px 12px 24px rgba(0, 0, 0, 0.1);
  }
`;

const EditBookingHeader: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const { booking } = useBookingState();
  const navigate = useNavigate();
  const location = useLocation();
  const size = React.useContext(ResponsiveContext);
  const small = "xsmall" === size || "medium" === size;

  if (!booking) {
    return null;
  }

  const cancelEdit = async () => {
    navigate("/book/" + booking.id + (location.search || ""));
  };

  return (
    <Wrapper width={"100%"} pad="medium">
      {("xlarge" === size || "large" === size || "medium" === size) && (
        <Image
          src="api/v1/custom/logo.png"
          height={40}
          width={120}
          style={{ position: "absolute", top: "20px", right: "35px" }}
        />
      )}
      <Heading level="3" margin={"none"}>
        {formatMessage(messages.header)}
      </Heading>
      <SlotComponent
        small={small}
        disabled={true}
        key={"slot_" + booking.id}
        video={booking.videoConsultation}
        serviceName={booking.mainService.name}
        careUnit={booking.careUnit}
        resource={booking.resource}
        startTime={booking.startTime}
        button={{
          label: formatMessage(messages.cancelButton),
          secondary: true,
          onSelect: cancelEdit,
        }}
      />
      <Heading level="4" margin={"none"}>
        {formatMessage(messages.chooseNewTime)}
      </Heading>
    </Wrapper>
  );
};

export default EditBookingHeader;
