import { Box, Heading, ThemeContext } from "grommet";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { setAvailableSlots, setPage, setSearchFilter } from "@Hooks/useBooking/actions";
import { BookingPage, SearchFilter, SelectOption, SelectServiceOption } from "@Hooks/useBooking/types";
import useBookingAction from "@Hooks/useBooking/useBookingAction";
import useBookingState from "@Hooks/useBooking/useBookingState";
import { pushErrorAction } from "@Hooks/useError/actions";
import useErrorAction from "@Hooks/useError/useErrorAction";
import theme from "@Style/theme";
import Button from "../Common/Button";
import SearchDateInput from "./FormFields/SearchDateInput";
import SearchFormField from "./FormFields/SearchFormField";
import SearchSelect from "./FormFields/SearchSelect";
import messages from "./messages";
import { GroupedSlots, Slot, Slots } from "@Api/bookingTypes";
import axios from "axios";
import qs from "qs";
import { domFloodProtectionSlotLimit } from "@App/App";

interface Props {
  setDrawLoading: (b: boolean) => void;
}

let hasMoreHits = false;

// - searchComplete property is part of gateway flooding protection implementation
// - searchActive is read to draw and undraw search button spinner
// - domFloodAborted is set when preventing DOM flooding, and is also used to halt search
// when user sets a different page
// - searchHalted is set when user sets a different page
let searchStatus = {
  searchActive: false,
  searchComplete: false,
  domFloodAborted: false,
  searchHalted: false
};

export const getSearchComplete = () => {
  return searchStatus.searchComplete;
};

const Search: React.FC<Props> = ({ setDrawLoading }) => {
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const { formatMessage, locale } = useIntl();
  const {
    booking,
    filter,
    careUnits,
    services,
    portalSettings,
    page
  } = useBookingState();
  const bookingDispatch = useBookingAction();
  const [loading, setLoading] = useState(false);
  const errorDispatch = useErrorAction();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (searchStatus.searchActive && !searchStatus.searchComplete) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    searchStatus.domFloodAborted = false;

    return () => {
      setAbortController(null);
      searchStatus.domFloodAborted = true;
      searchStatus.searchHalted = true;
    };
  }, []);

  const handleSubmit = async (event: any) => {
    searchStatus.searchActive = true;

    // Direct mobile users to results view
    bookingDispatch(setPage(BookingPage.SELECT_SLOT));

    // Reset page after previous search
    hasMoreHits = false;
    let none: Record<string, Slot[]> = {};
    if (page === BookingPage.SEARCH_SLOTS || page === BookingPage.SELECT_SLOT) {
      let renderObject: GroupedSlots = {
        slots: none,
        hasMoreHits: hasMoreHits
      };
      bookingDispatch(setAvailableSlots(renderObject));
    }

    // Instance controller for aborting queued calls
    const controller: AbortController = new AbortController();
    setAbortController(controller);

    // Abort all current calls when queuing new calls before current queue is complete
    if (abortController !== null) controller.abort(true);

    setLoading(true);
    setDrawLoading(true);

    const paramData = {
      startDate: filter.startDate?.format("yyyy-MM-DD"),
      earliestStartAt: filter.earliestStartAt
        ? filter.earliestStartAt.label
        : undefined,
      endDate: filter.endDate?.format("yyyy-MM-DD"),
      service: filter.service ? filter.service.id : undefined,
      resources:
        filter.resources.length > 0
          ? filter.resources.map((it) => it.value)
          : undefined,
      careUnits:
        filter.careUnits.length > 0
          ? filter.careUnits.map((it) => it.value)
          : undefined,
      minimumPeriod: filter.service ? filter.service.duration : undefined
    };

    // Compile queue of individual days from date span
    const startDate = filter.startDate;
    const endDate = filter.endDate;
    const dateArray: string[] = [];

    if (startDate && endDate) {
      const currentDate = startDate.clone();
      while (currentDate.format("yyyy-MM-DD") <= endDate.format("yyyy-MM-DD") && dateArray.length < 400) {
        dateArray.push(currentDate.format("yyyy-MM-DD"));
        currentDate.add(1, "day");
      }
    }

    let completeSlots: Record<string, Slot[]> = {};

    const fetchSlots = async (date: string, signal: any) => {
      try {
        // Format params
        paramData.startDate = date;
        paramData.endDate = date;

        // Call gateway and pass signal as option
        const resp = await axios.get<Slots>("/api/v1/availability", {
          signal,
          params: paramData,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "comma" });
          },
          validateStatus: function(status) {
            return status === 200;
          }
        });

        // If JWT is expired, send user to login page
        if (resp.status === 401) window.location.reload();

        // Read how many individual slot times are allowed to render
        let individualTimesRendered = 0;
        Object.values(completeSlots).forEach(slot => {
          individualTimesRendered += slot.length;
        });
        let slotsAllowedToRender = (domFloodProtectionSlotLimit - individualTimesRendered);

        // Remove individual slots exceeding DOM flood protection limit
        if (resp.data.slots.length > slotsAllowedToRender) {
          while (resp.data.slots.length > slotsAllowedToRender) {
            resp.data.slots.pop();
          }
          searchStatus.domFloodAborted = true;
        }

        // Process and type data
        let grouped = resp.data.slots.reduce(
          (result: Record<string, Slot[]>, slot: Slot) => {
            const key = moment(slot.startTime).startOf("day").toISOString();
            slot.serviceName = filter.service?.label || "";

            if (!result[key]) {
              result[key] = [];
            }
            result[key].push(slot);

            // Copy over data to render object
            Object.keys(result).forEach(key => {
              completeSlots[key] = result[key];
            });

            return result;
          },
          {});

        // Should render prompt at bottom of results?
        if (resp.data.hasMoreHits) {
          hasMoreHits = true;
        }

        // Type render object
        let renderObject: GroupedSlots = {
          slots: completeSlots,
          hasMoreHits: hasMoreHits
        };

        // Render all slots
        if (page === BookingPage.SEARCH_SLOTS || page === BookingPage.SELECT_SLOT) {
          bookingDispatch(setAvailableSlots(renderObject));
        }

      } catch {
        errorDispatch(
          pushErrorAction({
            title: formatMessage(messages.unkownError),
            message: formatMessage(messages.unkownErrorMessage),
            fixed: false
          })
        );
        setLoading(false);
      }
    };

    const fetchSlotsSequentially = async (array: string[], currentIndex = 0) => {
      if (searchStatus.domFloodAborted) {
        setDrawLoading(false);
        hasMoreHits = true;
        searchStatus.searchComplete = true;
        return;
      }
      if (currentIndex < array.length) {
        if (controller && controller.signal.aborted) {
          // Prevent gateway flooding
          errorDispatch(
            pushErrorAction({
              title: formatMessage(messages.callQueueLimitExceeded),
              message: formatMessage(messages.callQueueLimitExceededMessage),
              fixed: true
            })
          );
          return;
        }

        try {
          const signal: any = abortController ? abortController.signal : null;

          searchStatus.searchComplete = false;

          if (currentIndex === array.length - 1) {
            setDrawLoading(false);
          }

          // Read individual times currently rendered before fetching more slots
          let individualTimesRendered = 0;
          Object.values(completeSlots).forEach(slot => {
            individualTimesRendered += slot.length;
          });
          if (individualTimesRendered > domFloodProtectionSlotLimit) {
            setDrawLoading(false);
            hasMoreHits = true;
            return;
          }

          await fetchSlots(array[currentIndex], signal);

          await fetchSlotsSequentially(array, currentIndex + 1);
        } catch {
          errorDispatch(
            pushErrorAction({
              title: formatMessage(messages.unkownError),
              message: formatMessage(messages.unkownErrorMessage),
              fixed: false
            })
          );
          setLoading(false);
        } finally {
          setAbortController(null);
        }
      }
    };

    // Handle calls queued to be aborted
    if (abortController) {
      abortController.abort(true);
      setDrawLoading(false);
    } else {
      await fetchSlotsSequentially(dateArray);
    }

    searchStatus.domFloodAborted = false;
    searchStatus.searchComplete = true;

    // Enable search button
    setLoading(false);

    if (searchStatus.searchHalted) hasMoreHits = false;

    // Render final result. Will render "no available times for selected search options" prompt if search does not yield any bookable times
    if (page === BookingPage.SEARCH_SLOTS || page === BookingPage.SELECT_SLOT) {
      let renderObject: GroupedSlots = {
        slots: completeSlots,
        hasMoreHits: hasMoreHits
      };
      bookingDispatch(setAvailableSlots(renderObject));
    }

    searchStatus.searchActive = false;
    searchStatus.searchHalted = false;
  };

  const updateFilter = (newFilter: SearchFilter) => {
    bookingDispatch(setSearchFilter(newFilter));
  };

  const currentStartDate = (filter.startDate || moment()).format("yyyy-MM-DD");
  const searchSpan = portalSettings?.defaultDateRange || 3;
  const currentEndDate = (
    filter.endDate || moment(currentStartDate).add(searchSpan, "days")
  ).format("yyyy-MM-DD");
  const minDate = moment().format("yyyy-MM-DD");
  const maxDate = moment().add(1, "year").format("yyyy-MM-DD");

  return (
    <ThemeContext.Extend
      value={{
        global: {
          colors: {
            placeholder: theme.custom.menu.placeholder
          }
        }
      }}>
      <Box flex width="medium" direction="column">
        <Box
          style={{
            flex: 1,
            height: "120px",
            maxHeight: "120px",
            minHeight: "60px"
          }}
          direction={"row"}>
          <Heading
            alignSelf={"end"}
            level="1"
            margin={{ left: "5px", vertical: "small" }}
            color={theme.custom.menu.text}>
            {formatMessage(messages.header)}
          </Heading>
        </Box>
        <SearchFormField label={formatMessage(messages.type)}>
          <SearchSelect
            isMulti={false}
            isClearable={false}
            isDisabled={!!booking}
            value={filter.service}
            onChange={(selectedService) => {
              updateFilter({
                ...filter,
                service: selectedService as SelectServiceOption
              });
            }}
            options={services.map((o) => ({
              ...o,
              value: o.id + o.subContractId,
              label: o.name,
              duration: o.realTimeSpan
            }))}
            noOptionsMessage={formatMessage(messages.typeNoOptions)}
            placeholder={formatMessage(messages.servicePlaceholder)}
          />
        </SearchFormField>
        <SearchFormField label={formatMessage(messages.careUnit)}>
          <SearchSelect
            isMulti
            value={filter.careUnits}
            onChange={(selected) => {
              const value = Array.isArray(selected) ? selected : [];
              updateFilter({ ...filter, careUnits: value });
            }}
            options={careUnits.map((o) => ({ value: o.id, label: o.name }))}
            noOptionsMessage={formatMessage(messages.careUnitNoOptions)}
            placeholder={formatMessage(messages.careUnitPlaceholder)}
          />
        </SearchFormField>
        <SearchFormField label={formatMessage(messages.startDate)}>
          <SearchDateInput
            small={window.innerHeight < 870}
            value={currentStartDate}
            locale={locale}
            bounds={[minDate, maxDate]}
            placeholder={formatMessage(messages.datePlaceholder)}
            onChange={(value) => {
              const startDate = moment(value);
              if (
                startDate.isAfter(
                  moment(currentEndDate)
                    .endOf("day")
                    .subtract(searchSpan, "days")
                )
              ) {
                const endDate = moment(startDate).add(searchSpan, "days");
                updateFilter({
                  ...filter,
                  startDate: startDate,
                  endDate: endDate
                });
              } else {
                updateFilter({ ...filter, startDate: startDate });
              }
            }}
          />
        </SearchFormField>
        <SearchFormField label={formatMessage(messages.endDate)}>
          <SearchDateInput
            small={window.innerHeight < 870}
            value={currentEndDate}
            locale={locale}
            bounds={[currentStartDate, maxDate]}
            placeholder={formatMessage(messages.datePlaceholder)}
            onChange={(value) =>
              updateFilter({ ...filter, endDate: moment(value) })
            }
          />
        </SearchFormField>
        <SearchFormField label={formatMessage(messages.earliestStartAt)}>
          <SearchSelect
            isClearable={true}
            isSearchable={false}
            value={filter.earliestStartAt}
            menuPlacement={window.innerHeight > 870 ? "auto" : "top"}
            onChange={(selected) => {
              const value =
                selected === null || Array.isArray(selected)
                  ? undefined
                  : (selected as SelectOption);
              updateFilter({ ...filter, earliestStartAt: value });
            }}
            options={portalSettings.earliestStartAtHours}
            noOptionsMessage={formatMessage(messages.earliestStartAtNoOptions)}
            placeholder={formatMessage(messages.earliestStartAtPlaceholder)}
          />
        </SearchFormField>
        <Box
          style={{
            flexGrow: 1,
            flexShrink: 1,
            height: "300px",
            minHeight: "50px"
          }}
          direction="row"
          justify="center"
          pad={{ vertical: "medium" }}>
          <Button
            disabled={filter.service === undefined}
            width="160px"
            label={formatMessage(messages.button)}
            primary
            showSpinner={loading}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </ThemeContext.Extend>
  );
};

export default Search;
