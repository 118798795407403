import { Box, Heading, Image, ResponsiveContext, ThemeContext } from "grommet";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import theme from "@Style/theme";

import messages from "./messages";

interface Props {}

const NotFoundPage = styled(Box)`
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 50px;
  min-height: -webkit-fill-available;
`;

const NotFound: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const size = React.useContext(ResponsiveContext);

  return (
    <ThemeContext.Extend
      value={{
        global: { colors: { placeholder: theme.global.colors.brand } },
      }}>
      <NotFoundPage flex>
        <Box
          style={{ flex: 1, maxHeight: "120px", minHeight: "55px" }}
          direction={"row"}>
          {("xlarge" === size || "large" === size || "medium" === size) && (
            <Image
              src="api/v1/custom/logo.png"
              height={40}
              width={120}
              style={{ position: "absolute", top: "20px", right: "35px" }}
            />
          )}
          <Heading alignSelf={"end"} level="3" margin={{ vertical: "small" }}>
            {formatMessage(messages.notFoundMessage)}
          </Heading>
        </Box>
      </NotFoundPage>
    </ThemeContext.Extend>
  );
};

export default NotFound;
